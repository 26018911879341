import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import "../css/UserForm.css";

function UserForm() {
  const [formSchema, setFormSchema] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [startTime, setStartTime] = useState(null);
  const location = useLocation();

  const getIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("id");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const id = getIdFromUrl();

  useEffect(() => {
    if (id) {
      axios
        .get(
          `https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form-user?id=${id}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.form_schema || response.data.title) {
              setFormSchema(response.data.form_schema || []);
              setFormTitle(response.data.title || "");
              setErrorMessage("");
              setStartTime(new Date().getTime());
            } else {
              setErrorMessage("Response has been submitted already");
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setErrorMessage("Form not found");
          } else {
            setErrorMessage("An error occurred while fetching the form");
          }
        });
    }
  }, [location.search]);

  const handleSubmit = async (values) => {
    const endTime = new Date();
    const durationInSeconds = Math.floor((endTime - startTime) / 1000);

    // Duration tracking payload
    const durationPayload = {
      id: id,
      duration: durationInSeconds,
      startTime: new Date(startTime).toISOString(),
      endTime: endTime.toISOString(),
      status: "COMPLETED",
    };

    console.log("duration payload========", durationPayload);

    const hasFileUpload = formSchema.some(
      (field) => field.field_type === "file"
    );

    if (hasFileUpload && (!values.fileList || values.fileList.length === 0)) {
      toast.error("Please upload a file!");
      return;
    }

    let file_name = "";
    let fileName = "";
    if (hasFileUpload) {
      file_name = values.fileList[0].name;
      fileName = `${id}_${file_name}`;

      try {
        const response = await axios.get(
          `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/get-pre-signed-url?id=${id}&fileName=${file_name}`
        );
        const uploadUrl = response.data.uploadUrl;

        const uploadResponse = await axios.put(
          uploadUrl,
          values.fileList[0].originFileObj,
          {
            headers: {
              "Content-Type": values.fileList[0].type,
            },
          }
        );

        if (uploadResponse.status !== 200) {
          throw new Error("File upload failed");
        }
      } catch (error) {
        toast.error("Failed to upload file");
        return;
      }
    }

    const { fileList, ...formDataWithoutFileList } = values;
    const formObject = {
      id: id,
      formData: {
        ...formDataWithoutFileList,
        file_name: hasFileUpload ? fileName : undefined,
      },
    };

    try {
      const saveResponse = await axios.post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/survey-response-to-dynamo",
        formObject
      );

      if (saveResponse.status === 200) {
        try {
          console.log("Sending Survey response time data to Amazon DynamoDB");
          await axios.post(
            "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/survey-duration",
            durationPayload
          );
          console.log("Survey response time data sent successfully");
        } catch (durationError) {
          console.error("Failed to submit duration data:", durationError);
        }
        toast.success("Form submitted successfully!");
        setSubmissionMessage(
          "Thank you for your response! Your form has been submitted successfully."
        );
        setErrorMessage("");
      } else {
        toast.error("Failed to save form data");
        setSubmissionMessage("");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.message === "Response has been submitted already"
      ) {
        setErrorMessage("Response has been submitted already");
        setSubmissionMessage("");
      } else {
        setErrorMessage("Failed to save form data");
        setSubmissionMessage("");
      }
    }
  };

  const renderFormField = (field) => {
    switch (field.field_type) {
      case "text":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <Input placeholder={field.placeholder} />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <Input type="email" placeholder={field.placeholder} />
          </Form.Item>
        );

      case "number":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <InputNumber placeholder={field.placeholder} />
          </Form.Item>
        );

      case "password":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <Input.Password placeholder={field.placeholder} />
          </Form.Item>
        );

      case "checkbox":
        return (
          <Form.Item
            name={field.label.toLowerCase().replace(" ", "_")}
            valuePropName="checked"
          >
            <Checkbox>{field.placeholder}</Checkbox>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <Radio.Group>
              {field.options.map((option, index) => (
                <Radio key={index} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );

      case "dropdown":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <Select placeholder={field.placeholder}>
              {field.options.map((option, index) => (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );

      case "date":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <DatePicker />
          </Form.Item>
        );

      case "textarea":
        return (
          <Form.Item
            label={field.label}
            name={field.label.toLowerCase().replace(" ", "_")}
          >
            <TextArea placeholder={field.placeholder} />
          </Form.Item>
        );

      case "file":
        return (
          <Form.Item
            label={field.label}
            name="fileList"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Please upload a file!" }]}
          >
            <Upload action="/upload.do" listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                <PlusOutlined />
                <div style={{ marginTop: 5 }}>Upload</div>
              </button>
            </Upload>
          </Form.Item>
        );
      case "button":
        return (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {field.placeholder}
            </Button>
          </Form.Item>
        );
      default:
        return <div>Unknown Form Item</div>;
    }
  };

  return (
    <div className="user-body">
      <ToastContainer position="top-center" />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {submissionMessage && (
        <div className="success-message">{submissionMessage}</div>
      )}
      {!errorMessage && !submissionMessage && (
        <Form
          layout="vertical"
          style={{ width: "100%", justifySelf: "center" }}
          onFinish={handleSubmit}
        >
          <div
            style={{
              padding: "16px",
              border: "1px solid rgb(43, 46, 74)",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              height: "90vh",
              overflowY: "auto",
            }}
          >
            <div style={{ justifyItems: "center" }}>
              <h3
                style={{
                  marginBottom: "16px",
                  color: "rgb(43, 46, 74)",
                  fontSize: "x-large",
                }}
              >
                {formTitle}
              </h3>
            </div>
            {formSchema.map((field, index) => (
              <div key={index} style={{ marginBottom: "16px" }}>
                {renderFormField(field)}
              </div>
            ))}
          </div>
        </Form>
      )}
    </div>
  );
}

export default UserForm;
